<template>
    <div class="scratch-container">
        <div class="area">

            <MainArea ref="main" :showToolbox="false"></MainArea>

            <Bottom
                @selectSprite="selectSprite"
                @deleteSprite="deleteSprite">
            </Bottom>

        </div>

        <div id="blockly-code-generator" class="blockly-code-generator" style="display: none"></div>

        <Loading />

        <!-- 任务说明 -->
        <div class="dlg-wrapper" v-if="bShowInstruction" @click.stop="closeInstruction()">
            <div class="operate-instruction-container" @click.stop="noop()">
                <div class="pic">
                    <img :src="getExamQuestionImgUrl(paper.curQuestion.question_img)" v-if="paper && paper.curQuestion.question_img">
                </div>
                <div class="instruction">
                    <div class="title" style="margin-bottom: 20px">【任务名称】{{this.paper.curQuestion.question_name}}</div>
                    <div class="title">【任务目标】</div>
                    <div v-for="(content, index) in getInstructions" v-bind:key="index">
                        <div class="content">{{ content }}</div>
                    </div>
                </div>
                <div class="window-close">
                    <img :src="img.closeWhite" @click="closeInstruction()">
                </div>
            </div>
            <!-- <div class="btn-start" @click.stop="closeInstruction()">开始操作</div> -->
        </div>

        <!-- 交卷弹窗提示 -->
        <div class="dlg-wrapper" v-show="bShowHandInDlg" @click.stop="closeHandInDlg()">
            <div class="hand-in-container" @click.stop="noop()">
                <div class="tip">确认交卷后考试会立即结束</div>
                <!-- <div class="tip" style="margin-bottom: 25px;">你确定要现在交卷吗？</div> -->
                <div class="tip" style="margin-bottom: 25px;">请确认操作题试题已保存</div>
                <div class="op">
                    <div class="btn-op cancel" @click.stop="closeHandInDlg()">取消</div>
                    <div class="btn-op ok" @click.stop="handIn()">现在交卷</div>
                </div>
            </div>
        </div>

        <!-- 老师评分区 -->
        <div class="evaluation-area" >
            <!-- <div class="area"> -->
                <div class="area" v-show="curStep == 1">
                    <div class="instruction">
                        <div class="name">【任务名称】{{data.name}}</div>
                        <div class="name">【任务目标】</div>
                        <div v-for="(content, index) in data.instructions" v-bind:key="index">
                            <div class="content">{{content}}</div>
                        </div>
                    </div>
                    <div class="evaluation">
                        <div class="name">【评分标准】</div>
                        <div v-for="(evaluation, index) in data.evaluations" v-bind:key="index">
                            <div class="block">
                                <div class="desc">{{evaluation.desc}}</div>
                                <div class="row">
                                    <input type="text" v-model.number="userScoreList.scores[index]" @change="calcScore($event)" @keyup="calcScore($event)" />
                                    <div class="text">满分{{evaluation.score}}分</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="submit-row">
                        <div class="submit" @click.stop="grade">实操题评分</div>
                    </div>
                </div>
                <div class="area" v-show="curStep == 2">
                    <div class="chart-area">
                        <div id="chart-container" class="chart-container">
                            <div id="score-chart" class="score-chart"></div>
                        </div>
                        <div class="score">
                            <div class="row">
                                <div class="score-block">
                                    <div class="score-type color1">知识掌握</div>
                                    <div class="score-value">{{scoreData.scores[0]}} / {{scoreData.totalScores[0]}}</div>
                                </div>
                                <div class="score-block">
                                    <div class="score-type color2">技能运用</div>
                                    <div class="score-value">{{scoreData.scores[1]}} / {{scoreData.totalScores[1]}}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="score-block">
                                    <div class="score-type color3">信息文化</div>
                                    <div class="score-value">{{scoreData.scores[2]}} / {{scoreData.totalScores[2]}}</div>
                                </div>
                                <div class="score-block">
                                    <div class="score-type color4">创作实践</div>
                                    <div class="score-value">{{scoreData.scores[3]}} / {{scoreData.totalScores[3]}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="advice">
                            <div class="advice-text">【评价及学习建议】</div>
                            <textarea v-model="advice" />
                        </div>
                        <div class="submit-row">
                            <div class="submit" @click.stop="submit">下一位考生</div>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        </div>
    </div>
</template>

<script>
    import MainArea from '@/views/pages/ide/scratch/children/MainArea';
    import Bottom from '@/views/pages/ide/scratch/children/Bottom';
    import Loading from '@/views/components/mask/Loading';

    import Ide from '@/libs/ide/ide.js';
    import CoreData from '@/libs/runtime/coreData.js';
    import qs from 'qs'
    import cos from '@/utils/cos.js'
    import "@/libs/index.js";

    import * as echarts from 'echarts';

    import { mapGetters } from 'vuex'
    import { endExam } from "@/api/admin_exam";

    // common
    import { getCourseInfo, getSectionInfo } from '@/utils/common'
    // web/admin同名，但需要区分的接口
    import { sendReleaseMsg, getClassroomData, saveMySectionRes } from '@/api/web'
    // web
    import { genResId, createProject, saveProjectCover } from '@/api/web'
    // admin
    import { getAdminSectionData, saveAdminSectionRes } from '@/api/admin'

    import { getEvaluationData, getExamUserOperateData4Examiner,
             getExamUserScore, submitOperateScore, submitAdvice } from '@/api/examEval'

    export default {
        name: 'scratch',
        data: function() {
            return {
                img: {
                    closeWhite: "https://assets.koocoding.com/ide/images/close_white.png",
                },

                bShowInstruction: false,
                bShowHandInDlg: false,

                curEnv: '',

                resId: '',
                userResId: '',

                // 定时保存
                interval: null,

                examId: '',
                examNumber: '',
                qid: '',

                /**
                 * 考官评分相关
                 */
                scoreChart: null,
                chartOption: {
                    title: {
                        // text: '基础雷达图'
                    },
                    tooltip: {},
                    legend: {
                        data: ['分值', '得分', '平均分']
                    },
                    grid: {
                        x: 30,
                        y: 10,
                        x2: 30,
                        y2: 10,
                    },
                    radar: {
                        // shape: 'circle',
                        // name: {
                        //     textStyle: {
                        //         color: '#fff',
                        //         backgroundColor: '#999',
                        //         borderRadius: 3,
                        //         padding: [3, 5]
                        //     }
                        // },
                        center:['50%', '55%'],
                        radius:"60%",
                        nameGap: 10,
                        triggerEvent: false,
                        indicator: [
                            { name: '知识掌握', max: 40},
                            { name: '技能运用', max: 40, axisLabel:{show:false}},
                            { name: '信息文化', max: 40, axisLabel:{show:false}},
                            { name: '创作实践', max: 40, axisLabel:{show:false}},
                        ],
                        axisLabel:{
                            show:true,
                            // color:'#333',
                            showMinLabel: false
                        },
                        axisTick: {
                            show: true
                        },
                        splitNumber:4, //指示器轴的分割段数
                    },
                    series: [{
                        name: '阅卷评分',
                        type: 'radar',
                        // areaStyle: {normal: {}},
                        data : [
                            {
                                value : [0, 0, 0, 0],
                                name : '分值'
                            },
                            {
                                value : [0, 0, 0, 0],
                                name : '得分'
                            },
                            {
                                value : [0, 0, 0, 0],
                                name : '平均分'
                            }
                        ],
                        color:['#406cb4','#e37933','#a5a5a5']
                    }]
                },
                // 测评相关数据
                data: {
                    name: '',
                    instructions: [],
                    evaluations: [],
                },
                // 用于展示和操作各个分项的评分
                userScoreList: {
                    scores: []
                },
                // 考官评语
                advice: '',
                // 当前用户信息
                // curUser: {
                //     examId: '',
                //     examNumber: '',
                //     index: 0,
                //     count: 0,
                // },
                // 当前评分/评语步骤
                curStep: 1,  // 1:正在给实操题打分，2:正在填写评价
                // 当前用户操作分，总分，各维度得分
                scoreData: {
                    userScore: 0,
                    userOperateScore: 0,
                    operateScores: [], //考官给实操题的打分
                    totalScores: [], //各知识维度总分
                    scores: [], //各知识维度得分
                    averageScores: [], //各知识维度平均分
                }
            }
        },
        // // 不知道为啥没有调用成功？
        // // 但是暂时离开页面有提示，先用着，后面排查
        // beforeRouteLeave: function(to, from , next){
        //     let value = window.confirm("确定要离开当前页面吗？");
        //     if (value == true) {
        //         // 确定
        //         this.clearTimer()
        //         next()
        //     } else {
        //         // 取消
        //         next(false)
        //     }
        // },
        computed: {
            ...mapGetters([
                'ide',
                'dataAreaData',
                'actorAreaData',
                'curActor',
                'pid',
                'cid',
                'sid',
                'current',
                'paper',
            ]),
            getInstructions: function() {
                if (this.paper.curQuestion.question_text) {
                    return this.paper.curQuestion.question_text.split(/[\n]/)
                }
                return []
            },
        },
        created() {
        },
        mounted() {
            let that = this;

            // 加载userInfo
            this.loadUserInfo();

            this.clearTimer();
            this.interval = setInterval(that.autoSave, 60 * 1000); //暂时间隔60秒
        },
        unmounted() {
            this.clearTimer();
        },
        activated() {
        },
        methods: {
            getCourseInfo,
            getSectionInfo,
            async loadUserInfo() {
                await this.$store.dispatch('user/getUserInfo');
            },
            clearTimer() {
                if (this.interval) {
                    clearInterval(this.interval)
                    this.interval = null
                }
            },
            loadPage(examId, examNumber, qid, curEnv = null) {
                if (curEnv) {
                    this.curEnv = curEnv
                }
                this.examId = examId
                this.examNumber = examNumber
                this.qid = qid

                // 加载程序数据
                this.loadExamData(qid)

                this.loadExamInfo()
            },
            loadExamData(pid) {
                this.$store.commit('web/SetProjectId', pid)

                // 展示用户作品
                this.$store.commit('web/SetCurrent', 'user')

                // 加载作品数据
                this.$store.dispatch('web/LoadExamData', pid)
            },
            async loadExamInfo() {
                // 获取测评相关数据
                let res = await getEvaluationData({examId: this.examId, examNumber: this.examNumber});
                console.log(res.data)
                this.data.name = res.data.name;
                if (res.data.instruction) {
                    this.data.instructions = res.data.instruction.split(/[\n]/)
                }
                if (res.data.evaluation) {
                    let evaluations = res.data.evaluation.split(/[\n]/)
                    for (let i = 0; i < evaluations.length; i++) {
                        if (evaluations[i] != '') {
                            let kv = evaluations[i].split(';');
                            this.data.evaluations[i] = {
                                'desc': kv[0],
                                'score': kv[1]
                            }
                        }
                    }
                }

                res = await getExamUserScore({
                    examId: this.examId,
                    examNumber: this.examNumber,
                })
                console.log(res.data)
                // this.scoreData = res.data
                // this.userScoreList.scores = this.scoreData.operateScores
                // console.log(this.userScoreList.scores)
            },

            selectSprite(id) {
                this.$refs.main.selectSprite(id);
            },
            deleteSprite(id) {
                this.$refs.main.deleteSprite(id);
            },
            // 切换舞台
            reloadStage(nav) {
                this.$refs.main.reloadStage(nav);
            },
            handleCmd(data) {
                switch (data.cmd) {
                    case 'save':
                        this.save()
                        break
                    case 'release':
                        this.release()
                        break
                    case 'switchCode':
                        this.switchCode(data.params)
                        break
                    case 'switchNav':
                        this.switchNav(data.params)
                        break
                    case 'copyRes':
                        this.copyRes()
                        break
                    case 'toggleData':
                        this.toggleData()
                        break
                    case 'toggleShowDemoCode':
                        this.toggleShowDemoCode()
                        break
                    // Exam
                    case 'showInstruction':
                        this.showInstruction()
                        break
                    case 'showSubmitDlg':
                        this.showSubmitDlg()
                        break
                }
            },
            // // 上传完成后回调：
            // uploadDoneCallback(newCover) {
            //     // 更新数据库，保存新的coverId
            //     saveProjectCover({
            //         id: this.pid,
            //         newCover: newCover
            //     });
            // },
            autoSave() {
                // console.log('autoSave: ' + this.curEnv + '|' + this.current)
                if (this.curEnv == 'classroom' && this.current == 'user') {
                    this.save(false);
                }
            },
            async save(showAlert = true) {
                // console.log(this.curEnv);
                // console.log('auto save ~~~');
                // 保存截屏
                if (this.curEnv == 'ide') {
                    let cover = window.stageInstance.screenshot();
                    // console.log(cover);

                    // 想改成直接在前端保存，没成功
                    // 好像是base64解码的地方有问题

                    // // preg_match('/^(data:\s*image\/(\w+);base64,)/', $cover, $result)
                    // let coverData = cover.replace(/^data:image\/(\w*);base64,/, '');
                    // // console.log(coverData);
                    // let img = window.atob(coverData)
                    // console.log(img)
                    // await qiniu.uploadFile(img, 'covers', this.uploadDoneCallback)

                    await saveProjectCover(qs.stringify({
                        pid: this.pid,
                        cover: cover
                    }));
                }

                // 获取项目数据
                CoreData.saveBlock(this.curActor.id);
                let data = CoreData.getSaveData('user');

                if (this.curEnv == 'classroom') {
                    if (this.userResId == '') {
                        let res = await genResId()
                        this.userResId = res.data.resId

                        saveMySectionRes({ cid: this.cid, sid: this.sid, id: this.userResId })
                    }
                    await cos.putObject('project', 'project', this.userResId, JSON.stringify(data))

                } else if (this.curEnv == 'editor') {
                    if (this.resId == '') {
                        let res = await genResId()
                        this.resId = res.data.resId

                        saveAdminSectionRes({ cid: this.cid, sid: this.sid, resId: this.resId })
                    }
                    data = CoreData.getSaveData('demo');
                    await cos.putObject('project', 'project', this.resId + '.demo', JSON.stringify(data))

                    data = CoreData.getSaveData('user');
                    await cos.putObject('project', 'project', this.resId + '.res', JSON.stringify(data))

                } else if (this.curEnv == 'ide') {
                    // Admin中应该不会进入这个分支
                    if (this.pid != '') {
                        await cos.putObject('project', 'project', this.pid, JSON.stringify(data));

                    } else {
                        let res = await createProject({
                            name: this.projInfo.name,
                            type: 'SCRATCH'
                        })
                        // console.log(res)
                        if (res && res.data) {
                            let pid = res.data.pid

                            this.$store.commit('web/SetProjectId', pid)

                            this.$router.replace({ query: { id: pid } });

                            await cos.putObject('project', 'project', this.pid, JSON.stringify(data));
                        }
                    }
                } else if (this.curEnv == 'exam') {
                    if (this.pid != '') {
                        await cos.putObject('project', 'exam', this.pid, JSON.stringify(data));
                    }
                }
                if (showAlert) {
                    this.$store.dispatch('web/SetAlert', {type: 'success', msg: '保存成功'});
                }
            },
            async release() {
                this.save()

                await sendReleaseMsg({
                    type: 'project',
                    category: 'scratch',
                    id: this.curEnv == 'classroom' ? this.userResId : this.pid,
                    cid: ''
                });

                this.$store.dispatch('web/SetAlert', {type: 'success', msg: '发布成功'});
            },
            switchCode(params) {
                // 更新一下代码区内容
                Ide.workspace2code();
                // 切换代码区显示
                this.$store.commit('web/ShowArea', { name: 'CODE_AREA', isShow: params.showCode });
            },
            switchNav(params) {
                if (params.nav != this.current) {
                    CoreData.saveBlock(this.curActor.id);

                    this.$store.commit('web/SetCurrent', params.nav);
                    this.$refs.main.reloadStage(params.nav);

                    if (this.curEnv == 'editor') {
                        this.$store.commit('web/SetIsShowDemoCode', true);
                    }
                }
            },
            copyRes() {
                CoreData.copyRes('demo', 'user');

                this.$store.dispatch('web/SetAlert', {type: 'success', msg: '复制素材成功'});
            },
            toggleData() {
                let isShow = !this.ide.area.isShowDataArea;
                this.$store.commit('web/ShowArea', { name: 'DATA_AREA', isShow: isShow });

                // if (isShow && this.dataAreaData.length == 0) {
                if (isShow) {
                    // 展示初始化的数据区变量
                    this.$store.commit('web/InitDataAreaData');
                }
            },
            toggleShowDemoCode() {
                this.$store.commit('web/ToggleShowDemoCode');
            },

            // For Exam
            getExamQuestionImgUrl(key) {
                if (key) {
                    return 'https://assets.exam.koocoding.com/exam/' + key
                }
                return '';
            },
            showInstruction() {
                this.bShowInstruction = true;
            },
            closeInstruction() {
                // this.screenfull()
                this.bShowInstruction = false;
            },
            showSubmitDlg() {
                this.bShowHandInDlg = true;
            },
            closeHandInDlg() {
                this.bShowHandInDlg = false;
            },
            async handIn() {
                await endExam(this.examId);
                this.$router.push({ path: '/exam/end' });
            },
            noop() {},

            // 考官相关
            loadChart() {
                let chart = document.getElementById('score-chart');
                if (chart) {
                    this.scoreChart = echarts.init(chart)
                    this.scoreChart.setOption(this.chartOption)
                }
            },
            calcScore(event) {
                let totalScore = 0;
                // console.log(this.userScoreList.scores)
                for (let i = 0; i < this.userScoreList.scores.length; i++) {
                    totalScore += parseInt(this.userScoreList.scores[i])
                }
                this.scoreData.userOperateScore = totalScore
                // console.log(this.scoreData.userOperateScore)
            },
            // isNumber(value) {
            //     return typeof value === 'number' && !isNaN(value);
            // },
            async submit() {
                if (this.advice != '') {
                    await submitAdvice({
                        examId: this.examId,
                        examNumber: this.examNumber,
                        advice: this.advice,
                    });

                    location.reload();

                } else {
                    this.SetTopToast('请填写评语');
                }
            },
            async grade() {
                // console.log(this.scoreData.userOperateScore)
                await submitOperateScore({
                    examId: this.examId,
                    examNumber: this.examNumber,
                    score: this.scoreData.userOperateScore,
                    scoreList: this.userScoreList.scores
                });

                let res = await getExamUserScore({
                    examId: this.examId,
                    examNumber: this.examNumber,
                })
                this.scoreData = res.data
                // console.log(this.scoreData)
                this.chartOption.series[0].data = [
                    {value: this.scoreData.totalScores, name: '分值'},
                    {value: this.scoreData.scores, name: '得分'},
                    {value: this.scoreData.averageScores, name: '平均分'},
                ];

                this.curStep = 2;
                this.loadChart();
            },
            async gotoStep2() {
                let res = await getExamUserScore({
                    examId: this.examId,
                    examNumber: this.examNumber,
                })
                this.scoreData = res.data
                // console.log(this.scoreData)
                this.chartOption.series[0].data = [
                    {value: this.scoreData.totalScores, name: '分值'},
                    {value: this.scoreData.scores, name: '得分'},
                    {value: this.scoreData.averageScores, name: '平均分'},
                ];

                this.curStep = 2;
                this.loadChart();
            },
            setCurStep(curStep) {
                this.curStep = curStep;
                if (curStep == 2) {
                    this.gotoStep2();
                }
            },
        },
        components: {
            MainArea,
            Bottom,
            Loading,
        }
    }
</script>


<style lang="scss">
// 用于隐藏iframe区中的上下滚动条
html, body {
    overflow: hidden;
}
</style>

<style lang="scss" scoped>
.scratch-container {
    height: 100%;
    width: 100%;
    overflow: hidden;

    .area {
        width: 100%;
        height: 100%;
        margin: 0;
        border: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        z-index: 100;
    }

//   // 隐藏积木区横向&纵向滚动条
//   .blocklyScrollbarHandle {
//     opacity: 0;
//   }
}
</style>

<style lang="scss" scoped>
// 用于测评
.dlg-wrapper {
    position: fixed;
    z-index: 2100;
    // top: 70px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(50, 50, 50, 0.95);
    // border-top: 1px solid #222;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // color: #efefef;
    color: #555;
}

.operate-instruction-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 35px 35px;
    border-radius: 8px;
    position: relative;
    .pic {
        width: 400px;
        margin-right: 30px;
        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
    .instruction {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        letter-spacing: 1px;
        max-width: 600px;
        .title {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 12px;
        }
        .content {
            font-size: 18px;
            margin-bottom: 8px;
            display: flex;
            justify-content: flex-start;
            text-align: left;
        }
    }
    .window-close {
        position: absolute;
        top: 12px;
        right: 12px;
        height: 26px;
        width: 26px;
        cursor: pointer;
        img {
            height: 100%;
            width: 100%;
            background-color: #383838;
            border-radius: 50px;
            padding: 5px;
        }
    }
}
.btn-start {
    font-size: 18px;
    font-weight: bold;
    padding: 12px 24px;
    border: 2px solid #efefef;
    border-radius: 12px;
    background-color: #f4920f;
    margin: 50px 0;
    cursor: pointer;
}
.hand-in-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    padding: 25px 0 0 0;
    border-radius: 8px;
    color: #555;
    .tip {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        width: 400px;
        height: 30px;
        margin-bottom: 10px;
        color: #f13421;
    }
    .op {
        width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #ccc;
        .btn-op {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            font-weight: bold;
            flex: 1;
            cursor: pointer;
            &.cancel {
                border-right: 1px solid #ccc;
            }
            &.ok {
                color: #f13421;
            }
        }
    }
}

// 老师评分
.evaluation-area {
    position: fixed;
    top: 0;
    right: 0;
    width: 500px;
    bottom: 0;
    background-color: #383838;
    color: #fff;
    font-size: 14px;
    letter-spacing: 1px;
    overflow-y: scroll;
    .header {
        height: 90px;
        // width: 400px;
        width: 385px;
        z-index: 1000;
        position: fixed;
        top: 0;
        // background-color: #303030;
        background-color: #15141f;
        display: flex;
        justify-content: center;
        align-items: center;
        .evaluation-score {
            display: flex;
            justify-content: center;
            align-items: baseline;
            color: #fff;
            // margin-right: 20px;
            .text {
                font-size: 16px;
                font-weight: bold;
                margin: 0 5px;
            }
            .score {
                font-size: 60px;
                font-weight: bold;
            }
        }
        .submit {
            height: 35px;
            width: 80px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            // padding: 8px 18px;
            // border: 2px solid #fff;
            border-radius: 2px;
            background-color: #f4920f;
            // background-color: #ffc107;
            // background-color: #ffbf00;
            cursor: pointer;
        }
    }
    .area {
        position: absolute;
        top: 90px;
        left: 0;
        right: 0;
        bottom: 0;
        height: unset;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .chart-area {
            width: 400px;
        }
        .instruction {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 10px 15px;
            margin-top: 15px;
            .name {
                font-weight: bold;
                margin: 0 0 10px 0;
                color: #FFBF00;
            }
            .content {
                margin-top: 2px;
                display: flex;
                justify-content: flex-start;
                text-align: left;
            }
        }
        .evaluation {
            width: 100%;
            padding: 10px 15px;
            .name {
                font-weight: bold;
                text-align: left;
                color: #FFBF00;
            }
            .block {
                margin: 10px 0;
                .desc {
                    text-align: left;
                }
                .row {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin: 5px 0;
                    input[type=text] {
                        outline: none;
                        background-color: #515151;
                        color: #e6e6e6;
                        border: 0;
                        height: 28px;
                        width: 60px;
                        padding: 0 10px;
                        margin-right: 15px;
                        text-align: center;
                    }
                    .text {
                        width: 65px;
                        text-align: left;
                    }
                }
            }
        }
        .chart-container {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin-top: 10px;
            width: 100%;
            .score-chart {
                width: 350px;
                height: 300px;
                padding-top: 10px;
                border-radius: 8px;
                background-color: #fff;
            }
        }
        .score {
            padding: 10px 15px;
            margin-top: 15px;
            .row {
                padding: 0 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                .score-block {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .score-type {
                        font-size: 12px;
                        padding: 5px 12px;
                        border-radius: 4px;
                        margin-right: 8px;
                        &.color1 {
                            background-color: #de4307;
                        }
                        &.color2 {
                            background-color: #f29c2b;
                        }
                        &.color3 {
                            background-color: #4695d6;
                        }
                        &.color4 {
                            background-color: #8bc24c;
                        }
                    }
                    .score-value {
                        font-size: 15px;
                        font-weight: bold;
                        width: 65px;
                        text-align: right;
                    }
                }
            }
        }
        .advice {
            padding: 0 15px;
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            .advice-text {

            }
            textarea {
                resize: none;
                outline: none;
                background-color: #515151;
                color: #e6e6e6;
                border: 0;
                height: 120px;
                width: 100%;
                font-size: 14px;
                margin-top: 10px;
            }
        }
    }
    .submit-row {
            display: flex;
            justify-content: flex-end;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            .submit {
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                border-radius: 2px;
                background-color: #f4920f;
                cursor: pointer;
                margin: 10px 20px 20px 0;
                padding: 8px 15px;
            }
        }
}
</style>
