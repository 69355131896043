<template>

    <div class="page-container">
        
        <Header ref="header" @sendCmd="sendCmd" :showPages="true" :showCmd="true"></Header>
        <Scratch ref="page" />

    </div>

</template>

<script>
import Header from '@/views/pages/ide/examGrade/Header'
import Scratch from '@/views/pages/ide/examGrade/scratch/Scratch'
import { mapGetters } from 'vuex'
import { getGradeStudent } from '@/api/examEval'

export default {
    name: 'exam-scratch',
    data: function() {
        return {
        }
    },
    created() {
    },
    activated() {
    },
    computed: {
        ...mapGetters([
            'paper',
        ]),
    },
    mounted() {
        // 加载页面参数
        let examId = ''
        if (this.$route.query.examId) {
            examId = this.$route.query.examId
        }
        let examNumber = ''
        if (this.$route.query.examNumber) {
            examNumber = this.$route.query.examNumber
        }
        let qid = ''
        if (this.$route.query.qid) {
            qid = this.$route.query.qid
        }

        // 当前环境
        let curEnv = 'exam'

        this.loadGradeData(examId)

        // 加载数据
        this.$refs.page.loadPage(examId, examNumber, qid, curEnv)
    },
    methods: {
        sendCmd(data) {
            this.$refs.page.handleCmd(data)
        },
        async loadGradeData(examId) {
            let res = await getGradeStudent(examId);
            if (res.data && res.data.examType) {
                this.$store.commit('exam/SetGradeData', {
                    qid: res.data.qid,
                    examNumber: res.data.examNumber,
                    examType: res.data.examType,
                    leftCount: res.data.leftCount
                })
            }
        }
    },
    components: {
        Header,
        Scratch
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #222;
}
</style>
